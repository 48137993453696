<template>
    <div id="app_entry">
        <jy-container :menu="menu" :showLeft="true" canHide>
            <keep-alive>
                <router-view />
            </keep-alive>
        </jy-container>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            menu: [],
        }
    },
    activated() {
        this.getNav()
    },
    methods: {
        getNav() {
            let url = '/sys/menu/getAllMenu'
            this.$http.post(url).then(res => {
                this.menu = res.detail
            })
        },
    },
}
</script>

